import {css} from '@emotion/react';

export const baseDarkStyles = css`

  .sidebarTitle {
    background-color: #00152a;
    color: #ffffff;
  }

}
`;
