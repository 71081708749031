import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import config from '../../config';

export const langOptions = [
  ['日本語', 'ja', 'http://docs.subgrow.jp/'],
  ['EN', 'en', 'http://docs.subgrow.net/'],
];

export const LangSwitcher = ({ updateLang, sx }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const i18n = getI18n();
  // const classes = useStyles();

  useEffect(() => {
    const index = langOptions.findIndex((lang) => config.lang === lang[1]);
    setSelectedIndex(index !== -1 ? index : 1);
  }, []);

  // const handleLangClick = (event: any, newLang: string | undefined) => {
  //   i18n.changeLanguage(newLang);
  //   setSelectedIndex(langOptions.findIndex((l) => l[1] === newLang));
  //   updateLang?.(newLang);
  // };

  return (
    <ToggleButtonGroup
      value={langOptions[selectedIndex]}
      exclusive
      // onChange={handleLangClick}
      sx={sx}
    >
      {langOptions.map((option, index) => (
        <ToggleButton
          href={selectedIndex !== index ? option[2] : '#'}
          key={option[1]}
          value={option[1]}
          sx={{
            color: 'white',
            border: '1px solid #4c4c4c',
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#5c5c5c',
            },
            whiteSpace: 'nowrap',
          }}
        >
          {option[0]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
